<template>
  <div class="post-container">
    <div class="post-info">
      {{ translateTitle('开屏广告在用户每次启动app时显示') }}
    </div>
    <div class="post-advertising-space">
      <span>{{ translateTitle('广告位') }}</span>
      <template v-for="(item, index) in sliderList">
        <el-image
          v-if="$route.path.includes(item.path)"
          :key="index"
          :src="item.image"
        />
      </template>
      <p>{{ translateTitle($route.query.title) }}</p>
    </div>
    <Form
      :key="value"
      :column="column"
      :query="params"
      @handleCheckLink="handleCheckLink"
      @handleImageUrl="handleImageUrl"
      @handleVideoUrl="handleVideoUrl"
    />
    <div class="post-btn">
      <el-button
        :loading="loading"
        size="medium"
        type="primary"
        @click="onSubmit"
      >
        {{ translateTitle('保存') }}
      </el-button>
      <el-button size="medium" @click="cancel">
        {{ translateTitle('取消') }}
      </el-button>
    </div>
    <CheckLinkDialog ref="checklink" @handleLink="handleLink" />
  </div>
</template>

<script>
  import { translateTitle } from '@/utils/i18n'
  import { mapActions } from 'vuex'
  import config from '../config'
  import Form from '@/components/Form'
  import { saveAd } from '@/api/operate'
  import CheckLinkDialog from '@/components/JumpLink/checkLinkDialog.vue'
  export default {
    components: {
      Form,
      CheckLinkDialog,
    },
    props: {
      column: {
        type: Array,
        default: () => [],
      },
      fromQuery: {
        type: Object,
        default: () => ({}),
      },
    },
    data() {
      return {
        ...config,
        params: this.fromQuery,
      }
    },
    methods: {
      translateTitle,
      ...mapActions({
        delVisitedRoute: 'tabs/delVisitedRoute',
      }),
      async cancel() {
        await this.delVisitedRoute(this.$route.path)
        this.$router.go(-1)
      },
      handleCheckLink() {
        this.$refs.checklink.handleOpen()
      },
      // 地址
      handleLink(link) {
        this.params.link = link
      },
      // 获取图片上传地址
      handleImageUrl(prop, id) {
        this.params[prop] = id
      },
      // 获取视频上传地址
      handleVideoUrl(prop, query) {
        let imgProp = prop.replace('video', 'img')
        this.params[prop] = query.video_url
        this.params[`${prop}_id`] = query.video_aid
        if (!this.params[imgProp]) this.params[imgProp] = query.pic_aid
      },
      async onSubmit() {
        let params = JSON.parse(JSON.stringify(this.params))
        // 视频判断
        if (this.type === 3) {
          let video = []
          for (let item of this.videoKey) {
            if (params[`${item}_id`]) {
              video.push(params[`${item}_id`])
              delete params[item]
              delete params[`${item}_id`]
            } else {
              return this.$message.error(
                `${this.translateTitle('视频不能为空')}`
              )
            }
          }
          params.video = video.join(',')
        }
        if (!params.begin_time || !params.end_time) {
          return this.$message.error(`${this.translateTitle('请填写时间')}`)
        }
        if (!params.class_id) {
          return this.$message.error(`${this.translateTitle('请选择分类！')}`)
        }
        if (params.day_start_time > 24 || params.day_end_time > 24) {
          return this.$message.error(`${this.translateTitle('时间必须小于24')}`)
        }
        if (params.day_start_time > params.day_end_time) {
          return this.$message.error(
            `${this.translateTitle('开始时间不得大于结束时间')}`
          )
        }
        this.loading = true
        if (this.$route.query.id) params.id = this.$route.query.id
        // 配置项
        if (this.params.audit_config) {
          params.audit_config = {}
          params.audit_config.ac_op = params.ac_op
          for (let item of this.phoneConfigList) {
            if (this.params.audit_config.includes(item.id)) {
              params.audit_config[item.id] = true
            } else {
              params.audit_config[item.id] = false
            }
          }
          delete params.ac_op
        }
        params.config = { type: params.type }
        params.begin_time = params.begin_time / 1000
        params.end_time = params.end_time / 1000
        let pic = []
        for (let item of this.imgKey) {
          if (params[item]) {
            pic.push(params[item])
            delete params[item]
          }
        }
        params.pic = pic.join(',')
        params.extra_info = {
          day_end_time: params.day_end_time,
          day_start_time: params.day_start_time,
        }
        params.duration = params.duration || 3
        const data = await saveAd(params)
        this.loading = false
        if (data.success) {
          this.$message.success(`${this.translateTitle('保存成功')}`)
          this.cancel()
        } else {
          this.$message.error(data.msg)
        }
      },
    },
  }
</script>

<style lang="scss" scoped>
  $base: '.post';
  #{$base}-container {
    #{$base}-info {
      font-size: 13px;
      color: $base-color-text-secondary;
    }
    #{$base}-advertising-space {
      margin: 20px 0;
      span {
        float: left;
        width: 110px;
      }
      p {
        width: 160px;
        margin-left: 110px;
        text-align: center;
      }
      ::v-deep .el-image {
        display: table-cell;
        width: 160px;
        height: 68px;
        text-align: center;
        vertical-align: middle;
        background-color: #f5f5f5;
        img {
          width: auto;
          height: auto;
        }
      }
    }
    #{$base}-btn {
      margin: 40px 0 80px 110px;
    }
  }
</style>
