<script>
  import { translateTitle } from '@/utils/i18n'
  import { mapGetters } from 'vuex'
  import Post from '../../compenents/postCommon.vue'
  import { getAllClassificationPage } from '@/api/operate'
  import config from '../../config'
  // import magSample from '@/config/mag.sample'
  export default {
    components: {
      Post,
    },
    data() {
      return {
        ...config,
        params: {
          is_show: 1,
          title: null,
          ac_op: -1,
          type: 1,
          begin_time: null,
          end_time: null,
          first_page_show_style: 1,
          link: null,
          hot_word: null,
          pic: null,
          place: this.$route.query.place,
          duration: null,
          day_start_time: null,
          day_end_time: null,
          position: 1,
          video: null,
          show_label: -1,
          class_id: null,
          audit_config: [],

          i_four_img: null,
          android_i_img: null,
          i_ten_img: null,
          i_ten_r_img: null,
          i_four_video: null,
          android_i_video: null,
          i_ten_video: null,
          i_ten_r_video: null,
          i_four_video_id: null,
          android_i_video_id: null,
          i_ten_video_id: null,
          i_ten_r_video_id: null,
        },
      }
    },
    computed: {
      ...mapGetters({
        config: 'user/config',
      }),
      column() {
        return [
          {
            label: '是否显示',
            prop: 'is_show',
            type: 'radio',
            list: this.whetherList1,
          },
          {
            label: '广告备注',
            prop: 'title',
          },
          {
            label: '审核配置',
            prop: 'ac_op',
            type: 'radio',
            list: this.whetherList2,
          },
          {
            label: '配置项',
            prop: 'audit_config',
            type: 'checkbox',
            list: this.phoneConfigList,
            tips: '若选择审核版，则用户看不到配置的内容，若选择普通版，则审核人员看不到配置的内容',
            isHide: this.params.ac_op != 1,
          },
          {
            label: '类型选择',
            prop: 'type',
            type: 'radio',
            list: this.typeList,
          },
          {
            label: '样式选择',
            prop: 'first_page_show_style',
            type: 'radio',
            list: this.styleSelectionList,
            isHide: this.params.type != 1,
          },
          {
            label: '上传素材',
            type: 'text',
            tips: '动图大小建议不超过500KB，动图压缩地址：<a href="http://www.tuhaokuai.com/?b2" target="_blank">http://www.tuhaokuai.com/?b2</a>',
            isHide: this.params.type != 2,
          },
          {
            label: '上传素材',
            type: 'text',
            tips: '<p>需上传封面及视频，封面用于适配老版本，视频大小建议不超过2M，限MP4格式</p><p>封面不传默认取视频的第一帧</p>',
            isHide: this.params.type != 3,
          },
          {
            label: '上传图片',
            prop: 'i_four_img',
            type: 'uploadImage',
            width: 120,
            height: 160,
            eventName: 'handleImageUrl',
          },
          {
            label: '',
            prop: 'i_four_video',
            type: 'uploadVideo',
            width: 290,
            height: 160,
            eventName: 'handleVideoUrl',
            tips:
              this.params.type == 1
                ? '适配 iphone4-，建议尺寸640x744px'
                : '适配 iphone4-，建议尺寸640x960px',
            isHide: this.params.type != 3,
          },
          {
            label: '',
            prop: 'android_i_img',
            type: 'uploadImage',
            width: 120,
            height: 160,
            eventName: 'handleImageUrl',
          },
          {
            label: '',
            prop: 'android_i_video',
            type: 'uploadVideo',
            width: 290,
            height: 160,
            eventName: 'handleVideoUrl',
            tips:
              this.params.type == 1
                ? '适配安卓非全面屏和iphone4+，建议尺寸640x920px'
                : '适配安卓和iphone4+，建议尺寸640x1136px',
            isHide: this.params.type != 3,
          },
          {
            label: '',
            prop: 'i_ten_img',
            type: 'uploadImage',
            width: 120,
            height: 160,
            eventName: 'handleImageUrl',
          },
          {
            label: '',
            prop: 'i_ten_video',
            type: 'uploadVideo',
            width: 290,
            height: 160,
            eventName: 'handleVideoUrl',
            tips:
              this.params.type == 1
                ? '适配安卓全面屏和iPhone X，建议尺寸为750x1370px'
                : '适配iPhone X，建议尺寸为750x1624px',
            isHide: this.params.type != 3,
          },
          {
            label: '',
            prop: 'i_ten_r_img',
            type: 'uploadImage',
            width: 120,
            height: 160,
            eventName: 'handleImageUrl',
          },
          {
            label: '',
            prop: 'i_ten_r_video',
            type: 'uploadVideo',
            width: 290,
            height: 160,
            eventName: 'handleVideoUrl',
            tips:
              this.params.type == 1
                ? '适配iPhone XR 及iPhone XS Max，建议尺寸为828x1514px'
                : '适配iPhone XR 及iPhone XS Max，建议尺寸为828x1792px',
            isHide: this.params.type != 3,
          },
          {
            label: '点击热区文案配置',
            prop: 'hot_word',
            type: 'textarea',
            tips: '默认“点击查看内容详情”，15字以内，超过15字将显示不全，不配置文案则不展示热区，开屏只有点击热区才会进行跳转',
          },
          {
            label: '跳转',
            prop: 'link',
            // tips:
            //   this.config.hidden_mag_version != 1
            //     ? `${this.translateTitle(
            //         '选填，可以配置app内部任意页面的跳转链接。'
            //       )}<a href="${
            //         magSample.config_app_jump
            //       }" target="_blank">${this.translateTitle('查看示例')}</a>`
            //     : '',
            tips:
              this.config.hidden_mag_version != 1
                ? `${this.translateTitle(
                    '选填，可以配置app内部任意页面的跳转链接。'
                  )}`
                : '',
            // clickText: '选择跳转地址',
            // clickEventName: 'handleCheckLink',
          },
          {
            label: '显示时间',
            prop: 'duration',
            type: 'number',
            min: 0,
            tips: '该广告显示多长时间,如果不填写默认显示3秒(单位: 秒)',
          },
          {
            label: '是否显示广告标签',
            prop: 'show_label',
            type: 'radio',
            list: this.whetherList2,
          },
          {
            label: '广告分类',
            prop: 'class_id',
            type: 'select',
            optionLabel: 'name',
            list: this.advertisingTypeList,
          },
          {
            label: '开始时间',
            prop: 'begin_time',
            timeType: 'datetime',
            type: 'startDaterangePicker',
          },
          {
            label: '结束时间',
            prop: 'end_time',
            timeType: 'datetime',
            type: 'endDaterangePicker',
          },
          {
            label: '单天生效时间',
            type: 'inputGroup',
            placeholder: ['', ''],
            prop: ['day_start_time', 'day_end_time'],
          },
        ]
      },
    },
    created() {
      this.getAllClassificationPage()
    },
    methods: {
      translateTitle,
      getAllClassificationPage() {
        getAllClassificationPage({}).then((res) => {
          this.advertisingTypeList = res.list || []
        })
      },
    },
  }
</script>
